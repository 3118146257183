<template>
  <b-row>
    <b-col>
      <b-card title="My Units">
        <blueprint-data api-route-path="units" :fields="fields" :where="where" :q="q" no-actions no-new>
          <template v-slot:search>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Name">
              <b-form-input id="name" v-model="q" type="text" placeholder="Search..." />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Properties">
              <blueprint-search-input
                id="properties"
                v-model="properties"
                :multiple="true"
                placeholder="Type to search Properties"
                label="name"
                track-by="id"
                api-route-path="properties"
              />
            </b-form-group>
          </template>
          <template v-slot:propertyId="{ item }">
            {{ item.property.name }}
          </template>
        </blueprint-data>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMicrochip, faUsers,
} from '@fortawesome/free-solid-svg-icons';

library.add(faMicrochip, faUsers);

export default {
  data () {
    return {
      where: {},
      properties: [],
      q: null,
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'propertyId',
          label: 'Property',
          sortable: true,
          slot: true,
        },
      ],
    };
  },
  watch: {
    properties () {
      this.buildWhere();
    },
  },
  methods: {
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    parse (name, arr) {
      const ret = {};
      if (Array.isArray(arr) && arr.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        ret[name] = arr[0];
      } else if (Array.isArray(arr) && arr.length > 1) {
        ret[name] = { $in: arr };
      }
      return ret;
    },
    buildWhere () {
      const { properties } = this;

      const where = {
        ...this.parse('propertyId', properties),
      };
      this.where = where;
    },
  },
};
</script>
